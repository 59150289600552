@use "sass:color"; @use "sass:math"; @use "@/web/client/assets/css/variables" as *; 
ul.blog-posts{
    list-style: none;
    padding: 0;

    li{
        line-height: 2;
    }

    &.dates{
        li{
            display: grid;
            grid-template-columns: auto 1fr;
            gap: $padding;

            time{
                font-family: 'Courier New', Courier, monospace;
                white-space: nowrap;
            }

            @media (max-width: $mobile-breakpoint) {
                grid-template-columns: auto;
                gap: 0;

                &:not(:last-child){
                    margin-bottom: $padding * 2;
                }
            }
        }
    }
}
